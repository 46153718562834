import { Component, Input, Output, OnInit, EventEmitter, SimpleChanges } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject, forkJoin, from, of, Observable } from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';
import { StatisticsResponse } from '@app/models/StatisticsResponse';
import { ReportDefinitionParameter, ReportDefinition, LocalDate } from '@uni-entities';
import { FieldType, UniFieldLayout } from '@uni-framework/ui/uniform';
import { ConfirmActions, IModalOptions, IUniModal } from '@uni-framework/uni-modal/interfaces';
import {
    UniReportComments,
    ReportCommentConfig,
    ReportCommentSetup,
} from '@app/components/reports/modals/parameter/reportComments';
import { UniReportSendModal } from './reportSendModal';
import { TofEmailModal, UniModalService } from '@uni-framework/uni-modal';
import { ToastService, ToastType, ToastTime } from '@uni-framework/uniToast/toastService';
import { environment } from 'src/environments/environment';
import { cloneDeep } from 'lodash-es';
import { theme } from 'src/themes/theme';
import { ReportParamSearchService } from '../../report-param-search-service';
import { FinancialYearService } from '@app/services/accounting/financialYearService';
import { ErrorService } from '@app/services/common/errorService';
import { StatisticsService } from '@app/services/common/statisticsService';
import { ReportDefinitionParameterService } from '@app/services/reports/reportDefinitionParameterService';
import { BrowserStorageService } from '@uni-framework/core/browserStorageService';

@Component({
    selector: 'uni-report-params-modal',
    template: `
        <section role="dialog" class="uni-modal" [ngStyle]="{ display: hidden ? 'none' : 'flex' }" style="width: 45rem">
            <header>{{ options.header }}</header>

            <article [attr.aria-busy]="busy" class="modal-content">
                <p [innerHtml]="options.message"></p>

                <p class="warn" *ngIf="options.warning">
                    {{ options.warning }}
                </p>

                <uni-form
                    [fields]="fields$"
                    [model]="model$"
                    [config]="formConfig$"
                    (changeEvent)="onChangeEvent($event)"
                >
                </uni-form>
            </article>

            <div *ngIf="options?.data?.parameters?.length > 0" class="rememberForm">
                <rig-checkbox [(ngModel)]="rememberSelection" (change)="onRememberChange()"> Husk utvalg </rig-checkbox>
            </div>

            <footer>
                <button *ngIf="options.buttonLabels.cancel" class="secondary pull-left" (click)="cancel()">
                    {{ options.buttonLabels.cancel }}
                </button>

                <button *ngIf="commentConfig" class="secondary" (click)="editComments()">
                    Kommentarer ({{ comments?.length }})
                </button>

                <button class="secondary" (click)="trySend()">Epost</button>
                <button *ngIf="options.buttonLabels.accept" class="c2a" id="good_button_ok" (click)="accept()">
                    {{ options.buttonLabels.accept }}
                </button>
            </footer>
        </section>
    `,
})
export class UniReportParamsModal implements IUniModal, OnInit {
    @Input() options: IModalOptions = {};
    @Output() onClose: EventEmitter<any> = new EventEmitter();

    busy: boolean = false;
    layout: string = undefined;
    fields$: BehaviorSubject<any[]> = new BehaviorSubject([]);
    formConfig$: BehaviorSubject<any> = new BehaviorSubject({});
    model$: BehaviorSubject<any> = new BehaviorSubject({});
    commentConfig: ReportCommentConfig;
    comments: any[];
    rememberSelection: boolean = false;
    hidden = false;

    private browserStorageItemKey: string;
    private browserStorageReportParams: any;
    private hasParameterDependency: boolean = false;
    private report: ExtendedReportDefinition;
    private activeYear: number;

    constructor(
        private browserStorageService: BrowserStorageService,
        private reportDefinitionParameterService: ReportDefinitionParameterService,
        private statisticsService: StatisticsService,
        private errorService: ErrorService,
        private financialYearService: FinancialYearService,
        private uniModalService: UniModalService,
        private toastService: ToastService,
        private reportParamSearchService: ReportParamSearchService,
    ) {
        // Queries could give different results when changing current year
        this.financialYearService.lastSelectedFinancialYear$.subscribe((res) => {
            if (this.activeYear && this.activeYear != res.Year && !this.rememberSelection) {
                this.reportDefinitionParameterService.invalidateCache();
            }
            this.activeYear = res.Year;
        });
    }

    private fixDimensionParamValues(params: ExtendedReportDefinitionParameter[]) {
        const [fromProject, toProject] = params.filter(
            (param) => param.SearchModel?.toLowerCase() === 'project.projectnumber',
        );
        const [fromDepartment, toDepartment] = params.filter(
            (param) => param.SearchModel?.toLowerCase() === 'department.departmentnumber',
        );

        if (fromProject && !fromProject.value) {
            fromProject.value = '';
        }

        if (toProject && !toProject.value) {
            toProject.value = 'z';
        }

        if (fromDepartment && !fromDepartment.value) {
            fromDepartment.value = '';
        }

        if (toDepartment && !toDepartment.value) {
            toDepartment.value = 'z';
        }
    }

    fetchEditedParams() {
        const model = this.model$.getValue();
        if (this.rememberSelection) {
            this.browserStorageService.setItemOnCompany(this.browserStorageItemKey, model);
        }

        this.report.parameters.map((param) => {
            if (!model[param.Name] && param.Type === 'Number') {
                model[param.Name] = 0;
            }

            param.value = model[param.Name];

            if (param.Type === 'Boolean') {
                if (param.value === true) {
                    param.value = 1;
                } else {
                    param.value = 0;
                }
            } else if (param.Name === 'OrderBy') {
                const source: any[] = JSON.parse(param.DefaultValueList);
                const selectedSort = source.find((element) => element.Label === param.value);

                if (selectedSort) {
                    selectedSort.Value.forEach((sortValue) => {
                        switch (sortValue.Source) {
                            case 'OrderBy':
                                model['OrderBy'] = sortValue.Field;
                                break;
                            case 'OrderByGroup':
                                model['OrderByGroup'] = sortValue.Field;

                                const orderByGroup = <ExtendedReportDefinitionParameter>{
                                    Name: 'OrderByGroup',
                                    value: model['OrderByGroup'],
                                };
                                this.report.parameters.push(orderByGroup);
                                break;
                        }
                    });
                }

                param.value = model[param.Name];
            } else if (param.Type === 'Date' && param.value && !Date.parse(param.value)) {
                param.value = null;
            }
        });

        this.fixDimensionParamValues(this.report.parameters);
    }

    accept() {
        this.fetchEditedParams();
        this.onClose.emit(ConfirmActions.ACCEPT);
    }

    cancel() {
        this.onClose.emit(ConfirmActions.CANCEL);
    }

    ngOnInit() {
        this.options.closeOnEscape = false;
        if (!this.options.buttonLabels) {
            this.options.buttonLabels = {
                accept: 'Vis rapport',
                cancel: 'Avbryt',
            };
        }
        this.report = this.options.data;
        this.browserStorageItemKey = 'reportParamsForReportId:' + this.options.data.UniqueReportID;
        this.initForm();
    }

    onChangeEvent(changes: SimpleChanges) {
        const changedParamName: string = Object.keys(changes)[0];
        if (this.hasParameterDependency) {
            const changedParam = this.report.parameters.find((param) => param.Name === changedParamName);
            changedParam.value = changes[changedParamName].currentValue;

            // find the changed param's idx in {this.report.parameters} to get which params to re-resolve
            const changedParamIdx = this.report.parameters.findIndex((param) => param.Name === changedParamName);
            const paramsToBeResolved = this.report.parameters.slice(changedParamIdx + 1);
            this.resolveParamValues(paramsToBeResolved, false).then((params) => {
                // only fields that has been re-resolved should be re-generated
                let paramsIdx = 0;
                const fields: UniFieldLayout[] = this.fields$.getValue();
                for (let i = changedParamIdx + 1; i < this.report.parameters.length; i++) {
                    fields[i] = this.generateTooltip(this.generateFields(params[paramsIdx]));
                    paramsIdx++;
                }
                this.fields$.next(fields);

                const model = this.model$.getValue();
                params.map((param) => {
                    model[param.Name] = param.value;
                });
                this.model$.next(model);
            });
        }
        if (this.commentConfig && this.commentConfig.filter.indexOf(`{${changedParamName}}`) >= 0) {
            this.loadComments();
        }
    }

    onRememberChange() {
        if (!this.rememberSelection) {
            this.browserStorageService.removeItemFromCompany(this.browserStorageItemKey);
            this.initForm();
        }
    }

    reject() {
        this.onClose.emit(ConfirmActions.REJECT);
    }

    private fetchDefaultValues(
        params: ExtendedReportDefinitionParameter[],
    ): Promise<ExtendedReportDefinitionParameter[]> {
        return new Promise((resolve) => {
            // Defaultvalue-parameter-queries defined in report?
            const chunkOfQuerys = [];
            let topSourceIndex = -1;
            for (let i = 0; i < params.length; i++) {
                const parameter = params[i];

                if (parameter.DefaultValueSource && !parameter.value) {
                    const qIndex = parameter.DefaultValueSource.indexOf('?');
                    const query =
                        qIndex >= 0 ? parameter.DefaultValueSource.substr(qIndex + 1) : parameter.DefaultValueSource;
                    chunkOfQuerys.push(this.statisticsService.GetAllForCompany(`${query}`, this.report.companyKey));
                    topSourceIndex++;
                }

                // This should be set as standard for reports?
                if (
                    parameter.Name === 'System_PeriodAccountYear' ||
                    parameter.Name === 'PeriodAccountYear' ||
                    parameter.Name === 'yr'
                ) {
                    params[i].value = '' + this.financialYearService.getActiveYear();
                }

                parameter.SourceIndex = topSourceIndex;
            }
            if (chunkOfQuerys.length > 0) {
                forkJoin(chunkOfQuerys).subscribe((results: StatisticsResponse[]) => {
                    for (let i = 0; i < params.length; i++) {
                        const reportParam = params[i];

                        const dataset =
                            reportParam.SourceIndex !== undefined ? results[reportParam.SourceIndex] : undefined;
                        // If the value already has been set (if the param field is year), skip it!
                        if (dataset && dataset.Success && dataset.Data.length > 0 && !params[i].value) {
                            params[i].value = this.pickValueFromResult(reportParam, dataset.Data[0]);
                        }
                    }

                    resolve(params);
                });
                return;
            }

            // Auto-detect default-value:
            const param: ExtendedReportDefinitionParameter = <any>(
                params.find((x) => ['InvoiceNumber', 'OrderNumber', 'QuoteNumber'].indexOf(x.Name) >= 0)
            );
            if (param) {
                let searchParams = new HttpParams().set('model', 'NumberSeries').set('select', 'NextNumber');

                switch (param.Name) {
                    case 'InvoiceNumber':
                        searchParams = searchParams.set('filter', "Name eq 'Customer Invoice number series'");
                        break;
                    case 'OrderNumber':
                        searchParams = searchParams.set('filter', "Name eq 'Customer Order number series'");
                        break;
                    case 'QuoteNumber':
                        searchParams = searchParams.set('filter', "Name eq 'Customer Quote number series'");
                        break;
                }

                // Get param value
                this.statisticsService.GetDataByHttpParamsForCompany(searchParams, this.report.companyKey).subscribe(
                    (result: StatisticsResponse) => {
                        const value = result.Data[0].NumberSeriesNextNumber - 1;
                        if (value > 0) {
                            param.value = value;
                        }
                        resolve(params);
                    },
                    (err) => this.errorService.handle(err),
                );
            } else {
                resolve(params);
            }
        });
    }

    private generateFields(param: ExtendedReportDefinitionParameter): UniFieldLayout {
        let field: UniFieldLayout;
        if (param.SearchModel || param['ValueModel']) {
            const orderByDescending = param.Name.startsWith('To');
            const config = this.reportParamSearchService.getSearchConfig(
                param['ValueModel'] || param.SearchModel,
                param.value,
                false,
                orderByDescending,
            );
            if (config) {
                param.Type = 'lookup';
                param.lookupConfig = {
                    search: config.lookup,
                    getDefaultData: config.getInitData,
                    template: config.displayFunction,
                    valueProperty: 'value',
                };
            }
        }

        switch (param.Type ? param.Type.toLowerCase() : '') {
            case 'number':
                field = <UniFieldLayout>{
                    Property: param.Name,
                    Label: param.Label,
                    FieldType: FieldType.NUMERIC,
                    Hidden: !param.Visible,
                    Options: undefined,
                };
                break;
            case 'boolean':
                param.value = param.value === true || param.DefaultValue === 'true' || param.DefaultValue === '1';
                field = <UniFieldLayout>{
                    Property: param.Name,
                    Label: param.Label,
                    FieldType: FieldType.CHECKBOX,
                    Hidden: !param.Visible,
                    Options: undefined,
                };
                break;
            case 'lookup':
                field = <UniFieldLayout>{
                    Property: param.Name,
                    Label: param.Label,
                    FieldType: FieldType.AUTOCOMPLETE,
                    Hidden: !param.Visible,
                    Options: param.lookupConfig,
                };
                break;
            case 'dropdown':
                param.value = param.value || param.DefaultValue;
                if (param.DefaultValueList.includes('/api/statistics')) {
                    field = <UniFieldLayout>{
                        Property: param.Name,
                        Label: param.Label,
                        FieldType: FieldType.DROPDOWN,
                        Hidden: !param.Visible,
                        Options: {
                            source: param.source,
                            valueProperty: JSON.parse(param.DefaultValueLookupType).ValueProperty,
                            displayProperty: JSON.parse(param.DefaultValueLookupType).DisplayProperty,
                            searchable: false,
                            hideDeleteButton: true,
                        },
                    };
                } else {
                    const source = JSON.parse(param.DefaultValueList);
                    param.value = param.value || (param.Name === 'OrderBy' ? source[0].Label : source[0].Value);
                    field = <UniFieldLayout>{
                        Property: param.Name,
                        Label: param.Label,
                        FieldType: FieldType.DROPDOWN,
                        Hidden: !param.Visible,
                        Options: {
                            source: source,
                            valueProperty: param.Name === 'OrderBy' ? 'Label' : 'Value',
                            displayProperty: 'Label',
                            searchable: false,
                            hideDeleteButton: true,
                        },
                    };
                }
                break;
            case 'date':
                param.value = param.value || param.DefaultValue || new LocalDate();
                field = <UniFieldLayout>{
                    Property: param.Name,
                    Label: param.Label,
                    FieldType: FieldType.LOCAL_DATE_PICKER,
                    Hidden: !param.Visible,
                    Options: undefined,
                };
                break;
            case 'comment':
                this.commentConfig = this.commentConfig || { filter: param.DefaultValueLookupType };
                field = <UniFieldLayout>{
                    Property: param.Name,
                    Label: param.Label,
                    FieldType: FieldType.TEXTAREA,
                    Hidden: true,
                    Options: undefined,
                };
                break;
            default:
                param.value = param.value ? param.value.toString() : undefined;
                field = <UniFieldLayout>{
                    Property: param.Name,
                    Label: param.Label,
                    Hidden: !param.Visible,
                    Options: undefined,
                };
                break;
        }

        if (field) {
            const isDimension =
                field.Property === 'frompro' ||
                field.Property === 'topro' ||
                field.Property === 'fromavd' ||
                field.Property === 'toavd';

            if (isDimension) {
                field.FeaturePermission = 'ui.dimensions';
            }

            if (field.Property === 'budget') {
                field.FeaturePermission = 'ui.accounting.budget';
            }
        }

        return field;
    }

    private initForm() {
        if (this.report) {
            this.busy = true;
            this.hidden = this.report.action === 'send';
            this.layout = this.report.parameters?.find((x) => x.Name?.toLowerCase() === 'layout')?.value;
            this.loadParams(this.report)
                .pipe(switchMap((loadedParams) => this.resolveParamValues(cloneDeep(loadedParams), true)))
                .subscribe(
                    (resolvedParams) => {
                        this.report.parameters = resolvedParams;
                        this.fields$.next(
                            resolvedParams
                                .map((param) => this.generateFields(param))
                                .map((param) => this.generateTooltip(param)),
                        );
                        const model = this.model$.getValue();
                        resolvedParams.map((param) => {
                            if (param.Name === 'appName') param.value = theme.appName;
                            model[param.Name] = param.value;
                        });
                        this.model$.next(model);
                        this.loadComments();
                        if (this.report.action == 'send') {
                            this.trySend(true);
                        }
                        this.busy = false;
                    },
                    (err) => this.errorService.handle(err),
                );
        }
    }

    private loadComments() {
        if (!this.commentConfig) {
            return;
        }
        let filter = this.commentConfig.filter;
        let id: any = '0';
        if (filter.indexOf('{') >= 0) {
            filter = this.replaceQueryParamWithValue(filter, this.report.parameters);
        }
        if (filter.indexOf('/') >= 0) {
            const parts = filter.split('/');
            if (parts.length === 2) {
                id = parts[1];
                filter = parts[0];
            }
        }
        this.commentConfig.entity = filter;
        this.commentConfig.id = id;
        this.commentConfig.companyKey = this.report.companyKey;
        const query = `model=comment&select=id as ID,text as Text&filter=entitytype eq '${filter}' and entityid eq '${id}'`;
        this.statisticsService.GetAllForCompany(query, this.report.companyKey).subscribe((res) => {
            this.comments = res.Data;
        });
    }

    public editComments() {
        this.uniModalService
            .open(UniReportComments, {
                data: <ReportCommentSetup>{
                    config: this.commentConfig,
                    comments: this.comments,
                },
            })
            .onClose.subscribe((commentsChanged) => {
                if (commentsChanged) {
                    this.loadComments();
                }
            });
    }

    public trySend(autoClose = false) {
        this.busy = true;

        // Should we get the contact-email from companysettings?
        const reportSelfKeyword = 'accounting';
        if (
            this.report &&
            this.report.Category &&
            this.report.Category.toLowerCase().indexOf(reportSelfKeyword) === 0
        ) {
            const route =
                'model=companysettings&select=defaultemail.emailaddress as Email' + '&join=&expand=defaultemail';
            // Fetch contact-email from exact company:
            this.statisticsService.GetAllForCompany(route, this.report.companyKey).subscribe((result) => {
                let email;
                if (result.Success && result.Data && result.Data.length > 0) {
                    email = result.Data[0].Email;
                }
                this.openAndSendReport(email, autoClose);
            });
            return;
        }
        this.openAndSendReport(undefined, autoClose);
    }

    private openAndSendReport(receiver?: string, autoClose = false, tofNumber?: number) {
        this.fetchEditedParams();
        const isForm = !!this.report.ReportType;
        const formKey = this.getFormKey();

        if (
            this.report &&
            this.report.Category &&
            (this.report.Category.toLowerCase() === 'sales.quote' ||
                this.report.Category.toLowerCase() === 'sales.order' ||
                this.report.Category.toLowerCase() === 'sales.invoice')
        ) {
            const type = this.report.Category.split('.')[1];

            this.uniModalService
                .open(TofEmailModal, {
                    data: {
                        entityID: formKey,
                        entityType: `Customer${type}`,
                        report: this.report,
                        hideReportPicker: true,
                    },
                })
                .onClose.subscribe(() => (this.busy = false));
        } else {
            const formKeyLabel =
                isForm && this.report.parameters.length > 0
                    ? ` ${tofNumber > 0 ? tofNumber : this.report.parameters[0].value}`
                    : '';
            const params = this.report.parameters.map((x) => ({ Name: x.Name, value: x.value }));
            if (this.layout) params.push({ Name: 'layout', value: this.layout });
            const options = {
                data: {
                    model: {
                        EmailAddress: receiver,
                        Subject: `${this.report.Name}${formKeyLabel}`,
                        Message: `Vedlagt finner du '${this.report.Name}${formKeyLabel}'`,
                        ReportDefinition: this.report.Name,
                    },
                    parameters: params,
                    form: { Name: this.report.Name },
                    company: this.report.company ? { CompanyName: this.report.company.Name } : undefined,
                },
            };

            this.uniModalService
                .open(UniReportSendModal, options)
                .onClose.subscribe((email) => {
                    this.busy = false;
                    if (email) {
                        email.EntityType = this.getEntityTypeFromReport(this.report);
                        email.EntityID = formKey;
                        this.sendReport(this.report.ID, email, params);
                    }
                })
                .add(() => {
                    if (autoClose) {
                        this.cancel();
                    }
                });
        }
    }

    private getFormKey() {
        if (!this.report.parameters) return 0;

        return (
            this.report.parameters?.find(
                (x) => x.ValueModel?.split('.')[1]?.toLocaleLowerCase() === 'id' || x.Name.toLocaleLowerCase() === 'id',
            )?.value ||
            this.report.parameters[0].value ||
            0
        );
    }

    private sendReport(reportID: number, details: any, parameters = null) {
        const http = this.statisticsService.GetHttp();
        const companyKey = this.report.companyKey;
        const route = 'emails/?action=send';

        const toast = this.toastService.addToast(
            'Sender e-post til ' + details.EmailAddress,
            ToastType.warn,
            0,
            details.Subject,
        );

        if (!parameters) {
            parameters = [];
            parameters.push({ Name: 'Id', value: details.EntityID });
        }

        parameters.push({
            Name: 'LogoUrl',
            value: environment.BASE_URL_FILES + '/api/image/?key=' + http.authService.getCompanyKey() + '&id=logo',
        });
        const email = {
            ToAddresses: [details.EmailAddress],
            CopyAddresses: details.SendCopy ? [details.CopyAddress] : '',
            Subject: details.Subject,
            Message: details.Message,
            ReportID: reportID,
            Parameters: parameters,
            EntityType: details.EntityType,
            EntityID: details.EntityID,
        };

        if (companyKey) {
            http.appendHeaders({ CompanyKey: companyKey });
        }
        return http
            .usingBusinessDomain()
            .asPOST()
            .withEndPoint(route)
            .withBody(email)
            .send({}, undefined, !companyKey)
            .pipe(map((response) => response.body))
            .subscribe(
                (success) => {
                    this.toastService.removeToast(toast);
                    if (success) {
                        this.toastService.addToast('E-post sendt', ToastType.good, ToastTime.short);
                    } else {
                        this.toastService.addToast(
                            'E-post ikke sendt',
                            ToastType.bad,
                            ToastTime.medium,
                            'Feilet i oppretting av jobb',
                        );
                    }
                },
                (err) => {
                    this.errorService.handle(err);
                },
            );
    }

    private getEntityTypeFromReport(report: ExtendedReportDefinition) {
        switch (report.ReportType) {
            // case 1:
            //     return 'CustomerInvoice';
            // case 2:
            //     return 'CustomerOrder';
            // case 3:
            //     return 'CustomerQuote';
            default:
                return report.Name;
        }
    }

    private loadParams(report: ExtendedReportDefinition): Observable<ExtendedReportDefinitionParameter[]> {
        return this.reportDefinitionParameterService.GetAll('filter=ReportDefinitionId eq ' + report.ID).pipe(
            map((params) => {
                if (!params || params.length === 0) {
                    return [];
                }
                return params;
            }),
            switchMap((params) => {
                this.browserStorageReportParams = this.browserStorageService.getItemFromCompany(
                    this.browserStorageItemKey,
                );
                if (this.browserStorageReportParams) {
                    this.rememberSelection = true;
                    return of(
                        params.map((param) => {
                            param.value = this.browserStorageReportParams[param.Name];
                            return param;
                        }),
                    );
                } else {
                    return from(this.fetchDefaultValues(params));
                }
            }),
        );
    }

    private pickValueFromResult(param: ExtendedReportDefinitionParameter, result: any): any {
        if (!!param.DefaultValue || param.DefaultValue === '0') {
            if (result.hasOwnProperty(param.DefaultValue)) {
                return result[param.DefaultValue];
            }
            return param.DefaultValue;
        }
    }

    // find the value of the parameter that is requested and return a query string that replaces {ParameterName} by the current value
    private replaceQueryParamWithValue(query: string, params: ExtendedReportDefinitionParameter[]): string {
        this.hasParameterDependency = true;
        const paramName = query.substring(query.indexOf('{') + 1, query.indexOf('}'));
        const param =
            params.find((parameter) => parameter.Name === paramName) ||
            this.report.parameters.find((parameter) => parameter.Name === paramName);
        const paramValue: string | number = param.value || param.DefaultValue;
        return query.replace(`{${paramName}}`, paramValue.toString());
    }

    private resolveParamValues(
        params: ExtendedReportDefinitionParameter[],
        isOnInit: boolean,
    ): Promise<ExtendedReportDefinitionParameter[]> {
        return this.resolvePromisesSequentially(
            params.map((param) => () => {
                const preset = <any>this.report.parameters?.find((x) => x.Name == param.Name);
                if (preset && preset.presetValue) {
                    param.value = preset.presetValue;
                    return Promise.resolve(param);
                }
                // check if parameter has an API source to get the list for dropdown
                if (param.Type === 'Dropdown' && param.DefaultValueList.includes('/api/statistics')) {
                    const qIndex = param.DefaultValueList.indexOf('?');
                    let query = qIndex >= 0 ? param.DefaultValueList.substr(qIndex + 1) : param.DefaultValueList;
                    // check if the API source is dependent of another parameter, then written as {ParameterName} instead of the value
                    // example => {PeriodID} in '/api/statistics?model=VatReport&select=ID&filter=TerminPeriodID eq {PeriodID}'
                    if (query.includes('{')) {
                        query = this.replaceQueryParamWithValue(query, params);
                    }
                    return this.statisticsService
                        .GetAllForCompany(`${query}`, this.report.companyKey)
                        .pipe(
                            catchError((err, obs) => this.errorService.handleRxCatch(err, obs)),
                            map((resp: StatisticsResponse) => resp.Data),
                            map((data: any[]) => {
                                param.source = data;
                                if (data && data[0] && (!isOnInit || !this.browserStorageReportParams)) {
                                    param.value = data[0][JSON.parse(param.DefaultValueLookupType).ValueProperty];
                                }
                                return param;
                            }),
                        )
                        .toPromise();
                }
                return Promise.resolve(param);
            }),
        );
    }

    // executes Promises sequentially
    // {functions} = An array of funcs that return promises
    private resolvePromisesSequentially<T>(functions: Array<() => Promise<T>>): Promise<Array<T>> {
        const reducer = (promise, func) => promise.then((result) => func().then(Array.prototype.concat.bind(result)));
        return functions.reduce(reducer, Promise.resolve([]));
    }

    generateTooltip(field): UniFieldLayout {
        if (field.Label.indexOf('#@') === -1) {
            return field;
        }
        const [labelText, tooltipText] = field.Label.split('#@');
        return Object.assign({}, field, {
            Label: labelText,
            Tooltip: {
                Type: 'info',
                Alignment: 'right',
                Text: tooltipText,
            },
        });
    }
}

interface ExtendedReportDefinitionParameter extends ReportDefinitionParameter {
    value?: any;
    source?: any[];
    SourceIndex?: number;
    lookupConfig?;
}

interface ExtendedReportDefinition extends ReportDefinition {
    parameters?: ExtendedReportDefinitionParameter[];
    companyKey?: string;
    company?: any;
    action?: 'send' | undefined;
}
