import { Injectable } from '@angular/core';
import { ItemSourceDetailsModalComponent } from '@app/components/common/modals/item-source-details-modal/item-source-details-modal.component';
import { UniTableColumn, UniTableColumnType } from '@uni-framework/ui/unitable';
import { UniModalService } from '@uni-framework/uni-modal';
import { InvoiceToJournalEntry } from '@uni-entities';
import { CompanySettingsService } from './companySettingsService';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AgriService {
    agriDetails: any[] = [];
    constructor(
        private modalService: UniModalService,
        private companySettingsService: CompanySettingsService,
    ) {}

    getAgriColumn(isJournalEntry: boolean, readonly: boolean) {
        const agriCol = new UniTableColumn('ItemSourceID', '', UniTableColumnType.Button)
            .setWidth('40px', false)
            .setAlignment('center')
            .setCls('no-cell-padding')
            .setHeaderIcon({
                icon: 'plant_filled',
                color: 'var(--input-icon-color)',
                materialIconsClassOverride: 'material-icons-outlined',
                size: 20,
            })
            .setButtonResolver((item) => ({
                label: '',
                action: () => {
                    const lineItem = isJournalEntry ? item?.toJS() : item;
                    const selectedAgriDetails = this.agriDetails.find((x) => x._guid === lineItem?._guid);
                    let data: any;
                    data = {
                        Quantity1: lineItem?.NumberOfItems,
                        UnitOfMeasure1ID: lineItem?.UnitOfMeasureID,
                        ProductID: lineItem?.ProductID,
                    };
                    if (selectedAgriDetails) {
                        data = {
                            ...data,
                            ...selectedAgriDetails?.data,
                        };
                    }

                    this.modalService
                        .open(ItemSourceDetailsModalComponent, {
                            header: 'Landbruksopplysninger',
                            data: {
                                readonly,
                                // TODO: remove isJournalEntry when BE is ready
                                isJournalEntry,
                                id: lineItem?._guid,
                                itemSourceId: lineItem?.ItemSourceID,
                                items: data,
                            },
                        })
                        .onClose.subscribe((data) => {
                            if (data) {
                                if (selectedAgriDetails) {
                                    selectedAgriDetails.data = data;
                                } else {
                                    const payload = {
                                        _guid: lineItem._guid,
                                        data,
                                    };
                                    this.agriDetails.push(payload);
                                }
                            }
                        });
                },
                class: `agri-cell-button tertiary ${item?.ItemSourceID ? 'agri-content' : 'agri-empty'}`,
            }));

        return agriCol;
    }

    getAgriDetails() {
        return this.agriDetails;
    }

    isAgriActivated() {
        return this.companySettingsService.getCompanySettings().pipe(
            map((setting) => {
                return setting.InvoiceToJournalEntry === InvoiceToJournalEntry.ItemSourceDimension ? true : false;
            }),
        );
    }
}
